<template>
  <div>
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="/organization/company/page">
      <el-button
        v-if="$auth('公司管理新增')"
        slot="toolbar"
        type="primary"
        size="mini"
        @click="$refs.editPage.open()">
        {{$l("common.add", "新增")}}
      </el-button>

      <el-button
        v-if="$auth('公司管理导入')"
        slot="toolbar"
        :loading="loading"
        type="primary"
        size="mini"
        @click="visible=!visible">
        导入
      </el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search label="公司名称">
            <el-input v-model="filter.name" type="iotDeviceModel"></el-input>
          </vm-search>
          <vm-search label="地区">
            <el-input v-model.trim="filter.districtName" clearable></el-input>
          </vm-search>
        </div>
      </template>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="name" label="公司名称" align="center"></el-table-column>
      <el-table-column prop="districtName" label="地区" align="center"></el-table-column>
      <el-table-column prop="creditCode" label="统一社会信用代码" align="center"></el-table-column>
      <el-table-column prop="contactName" label="公司联系人" align="center"></el-table-column>
      <el-table-column prop="contactPhone" label="公司联系电话" align="center"></el-table-column>
      <el-table-column prop="remark" label="备注" align="center"></el-table-column>


      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate" width="130px">
        <template slot-scope="scope">
          <el-button v-if="$auth('公司管理编辑')" type="primary" @click="$refs.editPage.open(scope.row.id)">{{$l("common.edit", "编辑")}}</el-button>
          <el-button v-if="$auth('公司管理删除')" type="danger" @click="deleteRow(scope.row)">{{$l("common.delete", "删除")}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
    <el-dialog :visible.sync="visible" width="450px">
      <vm-drag-upload-file
        url="excelTemplate/company.xlsx"
        action="organization/company/import"
        name="公司导入"
        download-name="公司模板.xlsx"
        @save-success="getList(-1)"></vm-drag-upload-file>
    </el-dialog>
  </div>
</template>

<script>
  import EditPage from "./Edit.vue";
  export default {
    components: {EditPage},
    data() {
      return {
        loading:false,
        data: [],
        filter: {
        },
        defaultProps: {
          children: "children",
          label: "label",
        },
        dialogVisible:false,
        width:200,
        visible:false,
      };
    },
    computed:{
      formatData:function() {
        console.log(this.filter,11);
        return [];
      },
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
        this.visible = false;
      },
      handleNodeClick(data) {
        console.log(data,89);
        this.filter={...this.filter,districtCode:data.id};
        this.$refs.vmTable.getList(0,{districtCode:data.id});
        console.log(data);
      },
      deleteRow(row) {
        this.$confirm(`确定删除${row.name}吗?`, this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete(`/organization/company/${row.id}`).then(() => {
            this.getList(-1);
            this.$message.success(row.name + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },

    },
  };
</script>

<style scoped>
.treeBox{
  border: 1px solid #E6E6E6;
  padding: 10px;
  margin-right: 10px;
  width: calc(100% - 1200px);
}
</style>
